.price-box .plan-icons {
  display: flex;
  margin: auto;
  height: 80px;
  width: 100px;
  font-size: 60px;
  justify-content: center;
  align-items: center;
  color: #314584;
  position: relative;
}

.price-box .plan-icons:before {
  content: "";
  height: 75px;
  width: 75px;
  background-color: #e2ecfe;
  border-radius: 75px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.price-box .plan-icons:after {
  content: "";
  height: 60px;
  width: 60px;
  background-color: #d4e3fd;
  border-radius: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nmbButton {
  min-width: auto !important;
  border-radius: 0px !important;
}

.centeredInput input {
  text-align: center !important;
}