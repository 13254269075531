.item-subtitle{
  text-transform: capitalize;
}

.fw-bold{
  font-weight: bold;
}

.d-none{
  display: none !important;
}

.bg-gradient-profile{
  background: rgb(235,240,246);
  background: linear-gradient(180deg, rgba(235,240,246,1) 8%, rgba(0,157,174,1) 60%, rgba(30,74,161,1) 100%);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 170px;
}

.pac-container{
  z-index: 9999;
}